







import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'header-opening-times'
})

export default class extends Vue {
  @Prop({required: true}) private openingTimes: object;
  @Prop() private open: string;
  @Prop() private closed: string;

  private timeFrom: string | null = null;
  private timeTo: string | null = null;

  private get isOpen(): boolean {
    const date = new Date();
    const timeToday = this.openingTimes[date.getDay()];

    if (timeToday === 'closed') {
      return false;
    }

    this.timeFrom = timeToday['from'].replace('.', ':');
    this.timeTo = timeToday['to'].replace('.', ':');

    return true;
  }

  private get getMessage(): string {
    return this.isOpen ?
      this.open.replace('[from]', this.timeFrom).replace('[to]', this.timeTo) :
      this.closed;
  }
}
