Array.from(document.getElementsByClassName('is-drilldown-submenu-parent')).forEach(firstEl => {
  Array.from(firstEl.getElementsByClassName('nested')).forEach(nestedEl => {
    if (nestedEl.getElementsByClassName('nested').length) {
      Array.from(nestedEl.getElementsByClassName('nested')).forEach(thirdLevel => {
        thirdLevel.parentElement.classList.add('is-drilldown-submenu-parent');
        let backLi = document.createElement('li');
        backLi.innerHTML = '<button>Terug</button>';
        backLi.classList.add('js-drilldown-back');
        thirdLevel.insertBefore(backLi, thirdLevel.children[0]);
      });
    }
  });
});

('nav>ul').dropDown(el => {
  const dropDown = el.getElementsByClassName('navigation-group-container');
  const backdrop = document.querySelector('.backdrop-overlay');
  if (dropDown.length > 0) {
    dropDown[0].style.display = 'block';
    backdrop.classList.add('_active');
  }
}, el => {
  const dropDown = el.getElementsByClassName('navigation-group-container');
  const backdrop = document.querySelector('.backdrop-overlay');
  if (dropDown.length > 0) {
    dropDown[0].style.display = 'none';
    backdrop.classList.remove('_active');
  }
});
