window.addEventListener('load', () => {
    const scroll = document.getElementById('scrolltotop');
    scroll.addEventListener('click', () => {
        window.scrollTo(0, 0);
    });

    window.addEventListener('scroll', () => {
        if (window.pageYOffset > 150) {
            scroll.classList.remove('hidden'); 
        } else {
            scroll.classList.add('hidden');   
        }
    });
});