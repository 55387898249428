












































import {Component, Vue, Watch} from 'vue-property-decorator';

@Component({
  name: 'header-cart'
})

export default class extends Vue {
  private get totalSegments(): Array<object> {
    const segmentsToShow = ['shipping', 'discount', 'subtotal'];
    return this.cartTotals ? this.cartTotals['total_segments'].filter(s => segmentsToShow.includes(s.code)) : [];
  }

  private get cartTotals(): object {
    return this.$store.getters['CheckoutCart/cartTotals'];
  }

  private get quoteItemCount(): number {
    return this.$store.state.CheckoutGlobal.quoteItemCount;
  }

  private get showCartPopup(): boolean {
    return this.$store.getters['CheckoutCart/showCartPopup'];
  }

  private get showProductAdded(): boolean {
    return this.$store.getters['CheckoutCart/showProductAdded'];
  }

  private closeCartPopup(): void {
    this.$store.dispatch('CheckoutCart/showCartPopup', false);
  }

  private dropdownEvent(e): void {
    if (this.quoteItemCount > 0 && window.outerWidth >= 768) {
      this.$store.dispatch('CheckoutCart/showCartPopup', e.type === 'mouseenter');
    }
  }

  @Watch('showCartPopup')
  closeShowProductAdded(newVal) {
    if (newVal) {
      const _this = this;
      setTimeout(function () {
        _this.$store.dispatch('CheckoutCart/showProductAdded', false);
      }, 4000);
    }
  }
}
