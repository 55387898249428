














































import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'search-form'
})

export default class extends Vue {
  private inputQuery: string = '';
  protected autocompleteData: Array<object> = [];
  private _throttle: number;
  private autocompleteLoading: boolean = false;
  private autocompleteRemove: boolean = false;
  private showAutocomplete: boolean = false;

  private fetchAutocompleteData(): void {
    clearTimeout(this._throttle);
    this._throttle = window.setTimeout(() => {
      this.autocompleteRemove = this.inputQuery.length > 0;
      if (this.inputQuery.length > 3) {
        this.autocompleteLoading = true;
        this.$solarClient.get('/api/catalog/search/product/list', {params: {query: this.inputQuery}}).then((response) => {
          this.autocompleteData = response.data.items;
          this.autocompleteLoading = false;

          this.showAutocomplete = true;
        });
      } else {
        this.showAutocomplete = false;
        this.autocompleteLoading = false;
      }
    }, 500);
  }

  private productImage(product): string {
    return Object.keys(product['gallery_images']).length ? product['gallery_images'][95][0] : `/area/web/default/assets/images${product['default_image']}`;
  }

  private clearSearchInput(): void {
    this.inputQuery = '';
    this.showAutocomplete = false;
    this.autocompleteRemove = false;
  }
}
