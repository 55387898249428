/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import ServiceContainer from '@flashpointbv/solar-service-container';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
import {UICoreSticky, UICoreOffCanvas} from '@flashpointbv/solar-ui-core';
import {SolarDataLayer} from '@flashpointbv/solar-datalayer/dist';

/**
 * Import events
 */
import './events/scrolltotop';

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);

Vue.use(VueI18n);
Vue.use(VueLazyload);
Vue.use(Vuex);

SolarDataLayer.init({
  "item_variant": "a101_kleur",
  "price": "filter_price",
  "old_price": "price_incl_tax"
});

/*
 * Create store instance and register the store modules
 */
import {CatalogCategory, CatalogProduct, CatalogBundleProduct, CatalogGroupedProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog/dist';
import {CheckoutGlobal, CheckoutCart, CheckoutCheckout, CheckoutShipping, CheckoutPayment, CheckoutTotals} from '@flashpointbv/solar-ui-checkout/dist';
import CatalogConfigurator from './stores/modules/views/configurator/index';
import CustomerWishlist from '../../../../../vendor/flashpoint/solar-wishlist/resources/resources/assets/js/stores/wishlist';
import CatalogPageBuilder from '../../../../../vendor/flashpoint/page-builder-landing-pages/resources/resources/assets/js/stores/page-builder/index';

const store = new Vuex.Store({
  strict: false
});

store.registerModule('CatalogCategory', CatalogCategory);
store.registerModule('CatalogPageBuilder', CatalogPageBuilder);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);

store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutCart', CheckoutCart);
store.registerModule('CheckoutCheckout', CheckoutCheckout);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutTotals', CheckoutTotals);

store.registerModule('CatalogConfigurator', CatalogConfigurator);
store.registerModule('CustomerWishlist', CustomerWishlist);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
  {key: 'search-form', concrete: require('./components/SearchForm').default},
  {key: 'header-cart', concrete: require('./components/HeaderCart').default},
  {key: 'header-wishlist', concrete: require('./components/HeaderWishlist').default},
  {key: 'header-opening-times', concrete: require('./components/HeaderOpeningTimes').default},
  {key: 'footer-subscribe', concrete: () => import('./components/FooterSubscribe.vue')},
  {key: 'home-highlight', concrete: () => import('./components/HomeHighlight.vue')},
  {key: 'home-products', concrete: () => import('./components/HomeProducts.vue')},
  {key: 'home-usps', concrete: () => import('./components/HomeUsps.vue')},
  {key: 'recent-products', concrete: () => import('./components/RecentProducts.vue')},
  {key: 'hotspot-block', concrete: () => import('./components/HotspotBlock.vue')},

  //Catalog list
  {key: 'catalog-category-view', concrete: () => import('./views/catalog/CatalogCategoryView.vue')},
  {key: 'catalog-search-view', concrete: () => import('./views/catalog/CatalogSearchView.vue')},
  {key: 'catalog-page-builder-view', concrete: () => import('./views/catalog/CatalogPageBuilderView.vue')},

  {key: 'sub-category-list', concrete: () => import('./components/catalog/category/SubCategoryList.vue')},
  {key: 'sub-category-list-item', concrete: () => import('./components/catalog/category/SubCategoryListItem.vue')},

  {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue')},
  {key: 'active-filter', concrete: () => import('./components/catalog/filter/ActiveFilter.vue')},
  {key: 'attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
  {key: 'swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
  {key: 'price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},
  {key: 'sub-categories', concrete: () => import('./components/catalog/filter/SubCategories.vue')},
  {key: 'catalog-product-active-filter-list', concrete: () => import('./components/catalog/ProductActiveFilterList.vue')},

  {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
  {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},

  {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/ProductListToolbar.vue')},
  {key: 'catalog-product-list-toolbar-active-filters', concrete: () => import('./components/catalog/ProductListToolbarActiveFilters.vue')},
  {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('./components/catalog/ProductListToolbarPaginator.vue')},
  {key: 'catalog-product-list-toolbar-size-select', concrete: () => import('./components/catalog/ProductListToolbarSizeSelect.vue')},
  {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/ProductListToolbarSortSelect.vue')},

  //Product configuration
  {key: 'catalog-product-configuration', concrete: () => import('./components/catalog/product/ProductConfiguration.vue')},
  {key: 'catalog-product-upsell', concrete: () => import('./components/catalog/product/productUpsell.vue')},
  {key: 'catalog-product-related', concrete: () => import('./components/catalog/product/ProductRelated.vue')},
  {key: 'catalog-product-price', concrete: () => import('./components/catalog/product/ProductPrice.vue')},
  {key: 'catalog-product-simple', concrete: () => import('./components/catalog/product/simple/ProductTypeSimple.vue')},
  {key: 'catalog-product-configurable', concrete: () => import('./components/catalog/product/configurable/ProductTypeConfigurable.vue')},
  {key: 'catalog-product-configurable-select', concrete: () => import('./components/catalog/product/configurable/type/OptionSelect.vue')},
  {key: 'catalog-product-bundle', concrete: () => import('./components/catalog/product/bundle/ProductTypeBundle.vue')},
  {key: 'catalog-product-bundle-select', concrete: () => import('./components/catalog/product/bundle/type/BundleSelect.vue')},
  {key: 'catalog-product-bundle-radio', concrete: () => import('./components/catalog/product/bundle/type/BundleRadio.vue')},
  {key: 'catalog-product-bundle-checkbox', concrete: () => import('./components/catalog/product/bundle/type/BundleCheckbox.vue')},
  {key: 'catalog-product-grouped', concrete: () => import('./components/catalog/product/grouped/ProductTypeGrouped.vue')},
  {key: 'catalog-product-grouped-product', concrete: () => import('./components/catalog/product/grouped/ProductTypeGroupedProduct.vue')},
  {key: 'catalog-product-configurator', concrete: () => import('./components/catalog/product/configurator/ProductConfigurator.vue')},
  {key: 'catalog-product-configurator-option', concrete: () => import('./components/catalog/product/configurator/ProductConfiguratorOption.vue')},
  {key: 'catalog-product-media', concrete: () => import('./components/catalog/product/ProductMedia.vue')},
  {key: 'quote-modal', concrete: () => import('./components/catalog/QuoteModal.vue')},
  {key: 'catalog-product-review-form', concrete: () => import('./components/catalog/product/ProductReviewForm.vue')},
  {key: 'customer-wishlist-index', concrete: () => import('./components/customer/wishlist/Index.vue')},

  //Cart
  {key: 'checkout-cart', concrete: () => import('./components/checkout/cart/Cart.vue')},
  {key: 'checkout-cart-items', concrete: () => import('./components/checkout/cart/CartItems.vue')},
  {key: 'checkout-cart-items-list', concrete: () => import('./components/checkout/cart/CartItemsList.vue')},
  {key: 'checkout-cart-totals', concrete: () => import('./components/checkout/cart/CartTotals.vue')},
  {key: 'checkout-cart-crosssells', concrete: () => import('./components/checkout/cart/ProductCrosssellList.vue')},

  //Checkout
  {key: 'checkout-checkout', concrete: () => import('./components/checkout/checkout/Checkout.vue')},

  {key: 'checkout-address-detail', concrete: () => import('./components/checkout/checkout/step/AddressDetail.vue')},
  {key: 'checkout-address-shipping', concrete: () => import('./components/checkout/checkout/address/ShippingAddress.vue')},
  {key: 'checkout-address-billing', concrete: () => import('./components/checkout/checkout/address/BillingAddress.vue')},
  {key: 'checkout-address-login', concrete: () => import('./components/checkout/checkout/address/CheckoutLogin.vue')},
  {key: 'checkout-address-autocomplete', concrete: () => import('./components/checkout/checkout/address/input/AddressAutocomplete.vue')},
  {key: 'checkout-address-form', concrete: () => import('./components/checkout/checkout/address/input/AddressForm.vue')},
  {key: 'checkout-address-select', concrete: () => import('./components/checkout/checkout/address/input/AddressSelect.vue')},
  {key: 'checkout-address-option', concrete: () => import('./components/checkout/checkout/address/input/AddressOption.vue')},

  {key: 'checkout-shipping-detail', concrete: () => import('./components/checkout/checkout/step/ShippingDetail.vue')},
  {key: 'checkout-shipping-methods-default', concrete: () => import('./components/checkout/checkout/shipping/methods/Default.vue')},
  {key: 'checkout-shipping-methods-pickup', concrete: () => import('./components/checkout/checkout/shipping/methods/Pickup.vue')},
  {key: 'checkout-shipping-methods-reservation', concrete: () => import('./components/checkout/checkout/shipping/methods/Reservation.vue')},

  {key: 'checkout-payment-detail', concrete: () => import('./components/checkout/checkout/step/PaymentDetail.vue')},
  {key: 'checkout-payment-methods-default', concrete: () => import('./components/checkout/checkout/payment/methods/Default.vue')},

  {key: 'checkout-summary', concrete: () => import('./components/checkout/checkout/summary/Summary.vue')},
  {key: 'checkout-coupon-form', concrete: () => import('./components/checkout/coupon/CouponForm.vue')},

  {key: 'success-datalayer', concrete: () => import('./components/checkout/SuccessDatalayer.vue')},

  //Reviews
  {key: 'kiyoh-reviews-block', concrete: () => import('./components/reviews/KiyohReviewsBlock.vue')},
  {key: 'kiyoh-reviews-banner', concrete: () => import('./components/reviews/KiyohReviewsBanner.vue')},

  //Sliders and swipers
  {key: 'swiper', concrete: () => import('./shaked-exports/swiper/swiper')},
  {key: 'swiper-slide', concrete: () => import('./shaked-exports/swiper/swiper-slide')},

  //Lazy load components
  {key: 'lazy-load', concrete: () => import('./components/LazyLoad.vue')},
]);

import nl_NL from './i18n/nl_NL';
import en_GB from './i18n/en_GB';

import {localize} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import nl from 'vee-validate/dist/locale/nl.json';

localize({
  en,
  nl
});

localize('nl');

const messages = {
  nl_NL,
  en_GB
};

const i18n = new VueI18n({
  locale: window.Locale,
  fallbackLocale: 'nl_NL',
  formatFallbackMessages: true,
  silentTranslationWarn: true,
  messages
});

new Vue({
  store,
  i18n,
  components: ServiceContainer().getComponents(),
  data: {
    csrfToken: window.csrfToken
  },
  async created() {
    await this.$store.dispatch('CheckoutGlobal/initialize');
  }
}).$mount('#solar-app');
