export default {
  //Search
  "Search": "Zoek",
  "Search for ‘couch’ of ‘bedroom’": "Zoek bijv. op ‘bank’ of ‘slaapkamer’",
  "More results for {inputQuery}": "Meer resultaten voor {inputQuery}",
  "No results found for {inputQuery}": "Geen resultaten gevonden voor {inputQuery}",

  //Header cart
  "Product has been added to cart": "Product toegevoegd aan winkelwagen",

  //Newsletter
  "Newsletter subscription": "Aanmelden nieuwsbrief",
  "e-mailaddress": "e-mailadres",
  "subscribe": "inschrijven",

  // Categorie
  "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
  "Product has been added": "Product is toegevoegd",
  "Page": "Pagina",
  "Filter": "Filteren",
  "{fromProducts} to {toProducts} of {totalProductCount} results": "Geen resultaten | 1 resultaat | {fromProducts} tot {toProducts} van {totalProductCount} resultaten",
  "Page {currentPage} of {totalPages}": "Pagina {currentPage} van {totalPages}",
  "Sort by": "Sorteren op",
  "Name (A-Z)": "Naam (A-Z)",
  "Name (Z-A)": "Naam (Z-A)",
  "Price (Ascending)": "Prijs (Oplopend)",
  "Price (Descending)": "Prijs (Aflopend)",
  "All": "Alle",
  "Show {totalItems} results": "Geen resultaten | 1 resultaat | Bekijk {totalItems} resultaten",
  "Show results": "Bekijk resultaten",
  "Active filters": "Actieve filters",
  "Show more": "Toon meer",
  "Show less": "Toon minder",
  "Price": "Prijs",
  "Enter a correct value": "Vul een geldige waarde in",
  "Categories": "Categorieën",
  "You have viewed {viewed} of 1 {total}": "Je hebt gekeken naar {viewed} van {total} artikelen",
  "{totalItems} products": "Geen artikelen | 1 artikel | {totalItems} artikelen",

  //Product
  "There are no available options for this product.": "Er zijn voor dit product geen beschikbare opties gevonden.",
  "Not in stock": "Niet op voorraad",
  "Choose a selection...": "Kies een selectie...",
  "Choose your {option}": "Selecteer je {option}",
  "Free shipping": "Gratis bezorging",
  "Select one of the required options.": "Selecteer één van de verplichte opties.",
  "Add to cart": "In winkelmand",
  "Save": "Bewaren",
  "Remove": "Verwijderen",
  "This product is in stock": "Dit product is op voorraad",
  "Available at:": "Op voorraad in:",

  //Cart
  "Shopping cart": "Winkelwagen",
  "To checkout": "Naar bestellen",
  "Secure connection": "Bij ons betaal je veilig",
  "Continue shopping": "Verder winkelen",
  "You have no product(s) in your shopping cart.": "Je hebt geen product(en) in je winkelwagen.",
  "Click {url} to continue shopping": "Klik {url} om verder te gaan met winkelen.",
  "here": "hier",
  "Loading shopping cart items...": "Winkelmandje aan het laden...",
  "Description": "Omschrijving",
  "Quantity": "Aantal",
  "Unit price": "Stukprijs",
  "Subtotal": "Subtotaal",
  "SKU": "Artikelnummer",

  //Checkout
  "Address": "Gegevens",
  "Billing address": "Factuurgegevens",
  "Shipping address": "Adresgegevens",
  "Shipping": "Bezorging",
  "Payment": "Betaling",
  "Back to cart": "Terug naar winkelwagen",
  "To shipping": "Naar bezorging",
  "To payment": "Naar betaling",
  "Complete order": "Afrekenen",
  "Name": "Voornaam",
  "Last name": "Achternaam",
  "Company": "Bedrijf",
  "Country": "Land",
  "-- Choose your country --": "-- Kies je land --",
  "Street": "Straat",
  "House number": "Huisnummer",
  "House no.": "Huisnr",
  "Addition": "Toevoeging",
  "Add.": "Toev.",
  "Postal code": "Postcode",
  "City": "Plaats",
  "Telephone": "Telefoon",
  "Billing address is different from the address above": "Factuuradres is anders dan bovenstaand adres",
  "Email address": "E-mail adres",
  "Password": "Wachtwoord",
  "You have an account. Would you like to {action}?": "Je hebt een account. Wil je {action}?",
  "login": "inloggen",
  "Log in": "Inloggen",
  "Forgot password?": "Wachtwoord vergeten?",
  "Add new address": "Nieuw adres toevoegen",
  "Select your address": "Selecteer je gewenste adres",
  "The most used payment method in The Netherlands": "De meest gebruikte betaalmethode in Nederland",
  "Without extra fees": "Zonder extra kosten",
  "Payment methods": "Betaalmethode",
  "Payment method": "Betaalmethode",
  "Shipping methods": "Bezorgmethodes",
  "Shipping method": "Bezorgmethode",
  "Overview of your order": "Overzicht van je bestelling",
  "Items": "Artikelen",
  "Cart totals": "Totaal overzicht",
  "Free": "Gratis",
  "Shipping & Handling": "Verzending & Verwerking",
  "Your order will be delivered on": "Je bestelling wordt bezorgd op",
  "Change delivery address": "Wijzig bezorgadres",
  "I have a discount code": "Ik heb een kortingscode",
  "Apply": "Toepassen",
  "Cancel": "Annuleren",
  "Your applied coupon code is {couponCodeInput}.": "Je toegepaste kortingscode is {couponCodeInput}.",
  "Choose your store": "Kies je afhaallocatie",
  "Store": "Winkel",
  "You can pick up your order at": "Je kunt je bestelling afhalen bij",
  "Click here to automatically retrieve your address": "Klik hier om je adres automatisch op te vragen",
  "We have found the following address": "We hebben het volgende adres gevonden",
  "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het juist is ingevuld?",
  "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
  "Choose your bank": "Kies je bank",
  "Choose your creditcard": "Kies je creditcard",
  "Grand total incl. 21% VAT": "Eindtotaal incl. 21% BTW",

  //Wishlist
  "My wishlist": "Mijn favorieten",
  "There are no products in your wishlist": "Je hebt nog geen favorieten toegevoegd.",

  //Hotspots
  "Shop now": "Shop nu"
}
